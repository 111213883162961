.pdf-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  background-color: #eeeeee;
  width: 100%;
  box-sizing: border-box;
}

canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
  max-width: 100%;
  height: auto;
}
/* #media queries */
@media (max-width: 768px) {
  .pdf-div {
    padding: 5px;
  }

  canvas.react-pdf__Page__canvas {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .pdf-div {
    padding: 5px;
  }
}
